* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: Kanit;
}
.link {
  display: flex;
  color: #000;
  padding: 15px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: #343891;
  color: #fff;
  transition: all 0.5s;
}
.active {
  background: #343891;
  color: #fff;
}
.icon,
.link_text {
  margin-left: 1.5rem;
  font-size: 20px;
}
