.css-zddlty-MuiButtonBase-root-MuiButton-root {
  width: 80px;
  background-color: rgb(0, 0, 0, 0.23) !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: "Kanit" !important;
  border-radius: 30px !important;
}
.css-1ndpvdd-MuiTableCell-root {
  font-family: "Kanit" !important;
  font-weight: 500 !important;
  color: #545859 !important;
}
.css-1yhpg23-MuiTableCell-root {
  font-family: "Kanit" !important;
  font-weight: 300 !important;
  line-height: 2 !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 8px !important;
  box-shadow: none !important;
}
.css-1q1u3t4-MuiTableRow-root {
  border: 1px solid rgba(224, 224, 224, 1);
}
